body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 300;
  color: var(--font-color);
  max-height: 100vh;
  overflow: hidden;

  --header-height: 2.8rem;
  --header-opened-height: 20rem;

  --font-color: #0c2403;
  --shaddow-color: #055c14;
  --gradient-start-color: #ffe000;
  --gradient-end-color: #799f0c;
  --button-bg-color: #00000066;
  --button-fg-color: #d6d6d6;
  --active-btn-color: #d7e221;

  /* 
  Light mode
*/
  --shaddow-color: #929292;
  --gradient-start-color: #ffffff;
  --gradient-end-color: #f3ffdb;
  --button-bg-color: #b0b0b066;
  --button-fg-color: #361919;
  --light-transparent-color: #c6c6c6f7;
   

  /* --shaddow-color: #670c1a;
  --gradient-start-color: #e51939;
  --gradient-end-color: #02a0dd; */

  /* --shaddow-color: #0179a9;
  --gradient-start-color: #02a0dd;
  --gradient-end-color: #e51939; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


b, .bold {
  font-weight: 500;
}

.btn {
  cursor: pointer;
}

.gradient {
  background: var(--gradient-start-color); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    2.492rad,
    var(--gradient-start-color),
    var(--gradient-end-color)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(2.492rad, var(--gradient-start-color), var(--gradient-end-color));
}

.shadow {
  box-shadow: 0px 0px 3px var(--shaddow-color);
}

html,
body,
#root,
.appWrap,
#map {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.icon {
  user-select: none
}

.fullMessage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fullMessage.loading {
  opacity: 0;
  animation: fadeInFromNone 2s ease-out;
  animation-iteration-count: infinite;
}

#pano {
  height: 0;
  width: 0;
  display: none;
}

#map, #pano.opened {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  transition: 0.3s height, 0.3s top;
}

header {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 29rem;
  margin-right: 1rem;
}

header .menu {
  padding: 0.3rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;  
  z-index: 10;
  position: relative;
  border-bottom-right-radius: 0.5rem;
  transition: 0.3s padding-right;
}

.panorama header .menu {
  padding-right: 3rem;
}

header .searchWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;
  position: relative;
  max-width: 30rem;
  width: 100%;
}


header .searchWrap .icon {
  position: absolute;
  right: 0.5rem;
  color: var(--button-fg-color);
  background-color: transparent;
}

header .search {
  padding: 0.8rem 2.5rem 0.8rem 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--gradient-start-color);
  border: 0;
  font-size: 1rem;
  width: calc(100% - 3rem);
}

header .menu .menuIcon {
  text-decoration: none;
  color: var(--button-fg-color);
  padding: 0.6rem;
  border-radius: 0.5rem;
  background-color: var(--gradient-start-color);
}


.slider {
  position: absolute;
  top: -100vh;
  left: 0.3rem;
  z-index: 7;
  width: calc(100% - 3.1rem);
  height:  fit-content;;
  transition: 0.3s top;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 0.8rem;
  line-height: 1.35rem;
  max-height: 50vh;
  overflow: auto;
}

.menuOpened .slider {
  display: block;
  top: 3.4rem;
}

.slider .title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.slider .title img {
  margin-right: 1rem;
}

.slider ul, .slider p{
  margin: 0.4rem 0;
}

.slider h3 {
  font-weight: 300;
  margin: 1rem 0 0.25rem 0;
}

.slider .icon.verified {
  background: var(--active-btn-color);
  font-size: 1rem;
  color: #FFFFFF;
  padding: 0.1rem;
  border-radius: 50%;
}

.slider .icon {
  font-size: 1.2rem;
  position: relative;
  top: 0.3rem
}

header  ul {
  padding: 0 1.5rem;
}

.menuOpened header {
  opacity: 1;
}
.menuOpened header .disclaimer {
  display: block;
}

header .disclaimer {
  text-align: center;
  font-size: 0.7rem;
  bottom: 0.5rem;
  width: 100%;
  display: none;
}

header .disclaimer a {
  text-decoration: underline;
  color: inherit;
}
header .disclaimer > .icon {
  font-size: 1rem;
  top: 0.2rem;
  color: #c1d402;
  position: relative;
  display: inline-block;
  text-shadow: 1px 1px 3px #c1d402;
  margin: 0 0.5rem;
}
header .beer {
    font-size: 0.7rem;
    margin-top: 0.1rem;
}

header .beer a {
  margin-left: 0.2rem;
}

header .beer .icon {
  font-size: 1rem;
  position: relative;
  top: 0.3rem;
  margin-left: 0.2rem;
  color: #c1d402;
}

.navigation {
  position: absolute;
  top: 3.6rem;
  left: 0.5rem;
  z-index: 10;    
  width: 3rem;
  height: 3rem;
  z-index: 4;
}

.navigation .mylocation {
  background: var(--gradient-start-color);
  display: block;
  position: absolute;
  text-decoration: none;
  color: var(--button-fg-color);
  border-radius: 50%;
  padding: 0.4rem;
  font-size: 1.5rem;
}

.info {
  position: absolute;
  transition: bottom 0.5s, opacity 0.25s;
  opacity: 0;
  z-index: 10;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 1rem 1rem 0.5rem;
  bottom: -100%;
  right: 0.5rem;
  left: 0.5rem;
  font-size: 0.85rem;
  max-width: 26rem;
}

.info.open {
  bottom: 0rem;
  opacity: 1;
}

.info .close {
  background: var(--button-bg-color);
  display: block;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  text-decoration: none;
  color: var(--button-fg-color);
  border-radius: 50%;
  padding: 0.3rem;
  font-size: 1.2rem;
  user-select: none
}

.info .address {
  margin-right: 2rem;
  margin-bottom: 0.5rem;
}

.info .address .text {
  display: flex;
  align-items: center;
}
.info .address .text .verified {
  background: var(--active-btn-color);
  font-size: 1rem;
  color: #FFFFFF;
  padding: 0.1rem;
  border-radius: 50%;
  margin-right: 0.25rem;
}

.info .address .detail{
  margin-top: 0.25rem;
}

.info .pickupTime .disclaimer {
  margin-left: 0.25rem;
}

.info .improving {
  margin-top: 1rem;
  font-size: 0.9rem;
}
.info .improving ul {
  padding: 0;
  margin: 0;

}
.info .improving li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
}

.info .improving li.disabled {
  opacity: 0.2;
}

.info .improving li .icon {
  font-size: 1.1rem;
  margin-right: 0.5rem;
}

.info .helpDisclaimer {
  margin-top: 0.5rem;
  font-size: 0.6rem;
  text-align: center;
}

.info .loading {
  margin-top: 0.5rem;
  height: 1px;
  top:1px;
  position: relative;
  overflow: hidden;
}
.info .loading.shown::after {
  content: ' ';
  width: 50px;
  height: 100%;
  display: block;
  left: 2rem;
  position: absolute;
  background-color: var(--font-color);
  animation: 2s horizontalLoading;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}


.info .buttons {
  padding-top: 0.5rem;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--light-transparent-color);;
}

.info .buttons .block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.info .buttons .button:first-child {
  margin-left: 0;
}

.info .buttons .button {
  display: flex;
  flex-direction: column;
  justify-content: unset;
  align-items: center;
  text-decoration: none;
  color: inherit;
  margin-left: 1rem;
  padding: 0.3rem;
  width: 3rem;
  border-radius: 0.2rem;
  text-align: center;
  font-size: 0.7rem;
  height: 3.3rem;
}

.info .buttons .button.active { 
  background: var(--active-btn-color);
}

.info .buttons .button.disabled { 
  opacity: 0.2;
}

.info .buttons .button .icon {
  margin-bottom: 0.2rem
}

@keyframes fadeInFromNone {
  0% {
      opacity: 0;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}

.searchWrap .searchMenu:empty:after {
  content: 'Your search did not match any postbox';
  text-align: center;
  width: 100%;
  display: block;
}
.searchWrap .searchMenu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  z-index: 100;
  padding: 0.2rem;
  max-height: 20rem;
  overflow: auto;
  border-radius: 0.5rem;
}

.searchWrap .searchMenu .item {
  padding: 0.4rem;
  font-size: 1rem;
  cursor: default;
}

.searchWrap .searchMenu .item-highlighted {
  background-color: var(--active-btn-color);
  border-radius: 0.3rem;
}

.cookieConsentWrapper {
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4ffdf8f;
}

.cookieConsentWrapper .banner {
  width: calc(90% - 2rem);
  max-width: 30rem;
  padding: 1rem;
  background: var(--gradient-start-color); /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    var(--gradient-start-color),
    var(--gradient-end-color)
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--gradient-start-color), var(--gradient-end-color));
}

.cookieConsentWrapper button.secondary {
  background-color: transparent;
}
.cookieConsentWrapper button {
  border: 1px solid var(--active-btn-color);
  margin-top: 2rem;
  border-radius: 0.5rem;
  background: var(--active-btn-color);
  color: var(--button-fg-color);
  padding: 0.4rem 1.5rem;
  margin-right: 0.5rem;
}

@keyframes horizontalLoading {
  0%   {left: -150px; width: 150px;}
  100% {left: 100%;width: 20px;}
}